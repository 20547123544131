import * as React from 'react';

import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import LockIcon from '@mui/icons-material/LockRounded';

import {
    BrandConfig,
    defaultBrandConfig,
    tenantOverrideKey,
    KnownTenants,
    ResourceName,
    TenantBasicSettings,
    TenantResourceList,
} from '../Store/Tenant';
import { fetchWrapper } from './FetchWrapper';

import { LogException, LogMessage, SeverityLevel } from './Logging';
import { assetBlobContainer } from './Constants';

import { knownAccountingGroupIds } from '../Models/AccountingCollectionGroup';

const validDomains = ['.strongbox.link', '.strongbox.ai'];

const subdomainMap: Record<string, KnownTenants> = {
    'dev': KnownTenants.strongbox,
    'featurestest': KnownTenants.strongbox,
    'alvarezandmarsal': KnownTenants.alvarezandmarsal,
    'alvarezandmarsal-enihealth': KnownTenants.alvarezandmarsalenihealth,
    'alvarezandmarsal-figsnt': KnownTenants.alvarezandmarsalfigsnt,
    'ampleo': KnownTenants.ampleo,
    'anders-audit': KnownTenants.andersaudit,
    'anderscpa': KnownTenants.anderscpa,
    'aprio': KnownTenants.aprio,
    'armanino': KnownTenants.armanino,
    'armanino-bos': KnownTenants.armaninobos,
    'armanino-sfo': KnownTenants.armaninosfo,
    'armaninollp': KnownTenants.armanino,
    'asentipartners': KnownTenants.asentipartners,
    'bakertilly': KnownTenants.bakertilly,
    'bloomllc': KnownTenants.bloomllc,
    'bmf-audit': KnownTenants.bmfaudit,
    'bmf-tas': KnownTenants.bmftas,
    'bmss': KnownTenants.bmss,
    'bmss-audit': KnownTenants.bmssaudit,
    'bnncpa': KnownTenants.bnncpa,
    'bpmcpa': KnownTenants.bpmcpa,
    'bpmcpa-audit': KnownTenants.bpmcpaaudit,
    'brumbyadvisory': KnownTenants.brumbyadvisory,
    'btcpa': KnownTenants.bennettthrasher,
    'calumetcapital': KnownTenants.calumetcapital,
    'calvettiferguson': KnownTenants.calvettiferguson,
    'cbh': KnownTenants.cbh,
    'cbcas': KnownTenants.cbcas,
    'cbh-datamigration': KnownTenants.cbhdatamigration,
    'cbiz': KnownTenants.cbizmhm,
    'cbiz-peadvisory': KnownTenants.cbizpeadvisory,
    'cck': KnownTenants.cck,
    'cds-dd': KnownTenants.cdsdd,
    'cfgi': KnownTenants.cfgi,
    'chase': KnownTenants.chase,
    'chase-sandbox': KnownTenants.chasesandbox,
    'citrincooperman': KnownTenants.citrincooperman,
    'citrincooperman-assessments': KnownTenants.citrincoopermanassessments,
    'clarknuber': KnownTenants.clarknuber,
    'cohen-audit': KnownTenants.cohenaudit,
    'cohencpa': KnownTenants.cohencpa,
    'cohnreznick': KnownTenants.cohnreznick,
    'cohnreznick-gcs': KnownTenants.cohnreznickgcs,
    'connorgp': KnownTenants.connorgp,
    'convergence-co': KnownTenants.convergenceco,
    'cpademo': KnownTenants.cpademo,
    'cpademo2': KnownTenants.cpademo2,
    'creativeplanning': KnownTenants.creativeplanning,
    'creativeplanning-audit': KnownTenants.creativeplanningaudit,
    'cricpa': KnownTenants.cricpa,
    'cshco': KnownTenants.cshco,
    'copperpointco': KnownTenants.copperpointco,
    'deandorton': KnownTenants.deandorton,
    'eidebailly-cas': KnownTenants.eidebaillycas,
    'eidebailly-tas': KnownTenants.eidebaillytas,
    'eidebailly-valuation': KnownTenants.eidebaillyvaluation,
    'elliottdavis-clientassessment': KnownTenants.elliottdavisclientassessment,
    'elliottdavis-datamigration': KnownTenants.elliottdavisdatamigration,
    'elliottdavis-mna': KnownTenants.elliottdavismna,
    'embarkwithus': KnownTenants.embarkwithus,
    'exbogroup': KnownTenants.exbogroup,
    'ey-latam': KnownTenants.eylatam,
    'ey-pl': KnownTenants.eypl,
    'ey-sg': KnownTenants.eysg,
    'ey-testing': KnownTenants.eytesting,
    'ey-us': KnownTenants.eyus,
    'ey-usconsumerhealth': KnownTenants.eyusconsumerhealth,
    'ey-usgps': KnownTenants.eyusgps,
    'ey-usindustrialsenergy': KnownTenants.eyusindustrialsenergy,
    'ey-ustmt': KnownTenants.eyustmt,
    'eytest': KnownTenants.ernstyoung,
    'ey': KnownTenants.ernstyoung,
    'ey-uscentral': KnownTenants.ernstyoung,
    'ey-useast': KnownTenants.ernstyoung,
    'ey-usfso': KnownTenants.eyusfso,
    'ey-de': KnownTenants.eyde,
    'ey-nl': KnownTenants.eynl,
    'ey-uki': KnownTenants.eyuki,
    'ey-uswest': KnownTenants.ernstyoung,
    'ey-au': KnownTenants.ernstyoungaustralia,
    'ey-oceania': KnownTenants.ernstyoungaustralia,
    'eyca': KnownTenants.ernstyoungcanada,
    '512financial': KnownTenants.fivetwelvefinancial,
    'frp': KnownTenants.frp,
    'gbq-audit': KnownTenants.gbqaudit,
    'gbq-tas': KnownTenants.gbqtas,
    'greerwalkeraudit': KnownTenants.greerwalkerTASAudit,
    'greerwalker': KnownTenants.greerwalkerTASAudit,
    'greerwalkercas': KnownTenants.greerwalkercas,
    'gt': KnownTenants.gt,
    'guardianduediligence': KnownTenants.guardianduediligence,
    'h2advisors': KnownTenants.h2advisors,
    'highrockpartners': KnownTenants.highrock,
    'hl': KnownTenants.houlihanlokey,
    'infinite-advisors': KnownTenants.infiniteadvisors,
    'intraprise': KnownTenants.intraprise,
    'intrinsicfirm': KnownTenants.intrinsic,
    'ironsidesadv': KnownTenants.ironsidesadv,
    'ispn': KnownTenants.ispn,
    'jtaylor': KnownTenants.jtaylor,
    'kahnlitwin': KnownTenants.kahnlitwin,
    'kalosllp': KnownTenants.kalosllp,
    'kanter': KnownTenants.kanter,
    'kanter-assessments': KnownTenants.kanterassessments,
    'klr-assessments': KnownTenants.klrassessments,
    'klr-audit': KnownTenants.klraudit,
    'klroutsourcing': KnownTenants.klroutsourcing,
    'km-tas': KnownTenants.kmtas,
    'kmco-audit': KnownTenants.kmcoaudit,
    'kroll': KnownTenants.kroll,
    'laniganryan': KnownTenants.laniganryan,
    'laniganryan-assessments': KnownTenants.laniganryanassessments,
    'lbmcw-squared': KnownTenants.lbmcwsquared,
    'mainsailpartners': KnownTenants.mainsailpartners,
    'marcum': KnownTenants.marcum,
    'marcum-tasne': KnownTenants.marcumtasne,
    'midcapadvisors': KnownTenants.midcapadvisors,
    'millercooper': KnownTenants.millercooper,
    'millercooper-cas': KnownTenants.millercoopercas,
    'millerkaplan-audit': KnownTenants.millerkaplanaudit,
    'mlrpc': KnownTenants.mlrpc,
    'mlrpc-audit': KnownTenants.mlrpcaudit,
    'modventuresllc': KnownTenants.modventuresllc,
    'msllc': KnownTenants.msllc,
    'numericacu': KnownTenants.numericacu,
    'oakbridgeas': KnownTenants.oakbridgeas,
    'onedigital': KnownTenants.onedigital,
    'palmtreellc': KnownTenants.palmtreellc,
    'pennsaxton': KnownTenants.pennsaxton,
    'pennsaxton-audit': KnownTenants.pennsaxtonAudit,
    'plantemoran-audit': KnownTenants.plantemoranaudit,
    'redridgeds': KnownTenants.redridgeds,
    'rklcpa': KnownTenants.rkl,
    'rklesolutions-data': KnownTenants.rklesolutionsdata,
    'rklvirtual-assessments': KnownTenants.rklvirtualassessments,
    'rklvirtual-outsourcing': KnownTenants.rklvirtualoutsourcing,
    'sevlaser': KnownTenants.sevlaser,
    'smithhoward': KnownTenants.smithhoward,
    'smithhoward-audit': KnownTenants.smithhowardaudit,
    'sockeye': KnownTenants.sockeye,
    'stout-ara': KnownTenants.stoutara,
    'stout-ta': KnownTenants.stoutta,
    'stout-fdd': KnownTenants.stoutta,
    '3pg-tas': KnownTenants.threepgtas,
    'sunveragroup': KnownTenants.sunveragroup,
    'sweeneyconrad-cas': KnownTenants.sweeneyconradcas,
    'sweeneyconrad-reporting': KnownTenants.sweeneyconradreporting,
    'swktech': KnownTenants.swktech,
    'tggaccounting': KnownTenants.tggaccounting,
    'thinkbrg': KnownTenants.thinkbrg,
    'tjt-audit': KnownTenants.tjtaudit,
    'tjtpa': KnownTenants.tjtpa,
    'pilot': KnownTenants.pilot,
    'pkfod': KnownTenants.pkfod,
    'plantemoran': KnownTenants.plantemoran,
    'pppllc': KnownTenants.pppllc,
    'prod-qa': KnownTenants.prodqa,
    'riveron': KnownTenants.riveron,
    'rsmus': KnownTenants.rsm,
    'rubinbrown': KnownTenants.rubinbrown,
    'sectest': KnownTenants.strongbox,
    'smith-leonard': KnownTenants.smithleonard,
    'sso-sandbox': KnownTenants.ssosandbox,
    'tannerco': KnownTenants.tanner,
    'svb': KnownTenants.svb,
    'torpago': KnownTenants.torpago,
    'trialta': KnownTenants.trialta,
    'trout-tas': KnownTenants.trouttas,
    'troutcapital': KnownTenants.troutcapital,
    'weaver-audit': KnownTenants.weaveraudit,
    'weaver-tas': KnownTenants.weavertas,
    'windhambrannon': KnownTenants.windhambrannon,
    'demo': KnownTenants.demo,
    'redpathcpas': KnownTenants.redpathcpas,
    'vmghealth': KnownTenants.vmg,
    'warrenaverett': KnownTenants.warrenaverett,
    'whitleypenn': KnownTenants.whitleypenn,
    'williamsmarston': KnownTenants.williamsmarston,
    'wipfli': KnownTenants.wipfli,
    'withum': KnownTenants.withum,
    'wolfandco-audit': KnownTenants.wolfandcoaudit,
    'wolfandco-oas': KnownTenants.wolfandcooas,
    'wolterskluwer': KnownTenants.wolterskluwer
}

export const defaultBasicTenantSettings: TenantBasicSettings = {
    connectWelcomeMessage: 'Welcome to the Strongbox Financial Data Portal',
    defaultResource: KnownTenants.defaultLender,
    favIconTitle: 'Strongbox Demo',
    showStrongboxLogo: false,
    visibleName: 'Strongbox',
};

/*
 * If you add a TenantResourceList object make sure that you add it to tenantResourceLists
 * below.  If you don't it won't actually be available for use.
 */

export const defaultTenantName = 'strongbox';

//  defaultLender:  strongbox, demo, numerated, oaknorth 
//  defaultAccountant: bennettthrasher, cpademo, mlrpc, wiss

const tenantWelcomeText: TenantResourceList = {
    resourceName: ResourceName.tenantWelcomeText,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    `Strongbox helps firms analyze financial data and prepare reports.`
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    'Strongbox helps accounting firms analyze financial data and prepare reports for clients.'
                ]
            }
        },
        {
            tenantName: KnownTenants.chase,
            resource: {
                paragraphs: [
                    'The Strongbox Portal for Chase for Business® Powered by Finagraph allows you to easily connect your accounting software to enable Chase to seamlessly gather the financial information it needs to assess your application.',
                    'Click on your accounting system below to learn how the connection works and the security features.'
                ]
            }
        },
        {
            tenantName: KnownTenants.chasesandbox,
            resource: {
                paragraphs: [
                    'The Strongbox Portal for Chase for Business® Powered by Finagraph allows you to easily connect your accounting software to enable Chase to seamlessly gather the financial information it needs to assess your application.',
                    'Click on your accounting system below to learn how the connection works and the security features.'
                ]
            }
        }
    ]
}

const tenantSelectAccountingSystemSupplemental: TenantResourceList = {
    resourceName: ResourceName.tenantSelectAccountingSystemSupplemental,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: []
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: []
            }
        },
        {
            tenantName: KnownTenants.chase,
            resource: {
                paragraphs: [ 'Don\'t see your accounting system listed above?  You will receive both an email and a mailed letter from Chase in the coming days that offer alternate options for providing your requested documentation.' ]
            }
        },
        {
            tenantName: KnownTenants.chasesandbox,
            resource: {
                paragraphs: [ 'Don\'t see your accounting system listed above?  You will receive both an email and a mailed letter from Chase in the coming days that offer alternate options for providing your requested documentation.' ]
            }
        }
    ]
}

const tenantBorrowerParametersBullets: TenantResourceList = {
    resourceName: ResourceName.tenantBorrowerParametersBullets,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    {
                        attributes: [{name: 'bullet', value: 'true'}],
                        content: 'Secure',
                    },
                    {
                        attributes: [],
                        content: 'Encryption helps protect your business\' financial data.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Private',
                    },
                    {
                        attributes: [{ name: 'replaceLenderName', value: 'true' }],
                        // lenderName is replaced at runtime so this is correct
                        // eslint-disable-next-line no-template-curly-in-string
                        content: 'Your credentials will never be made accessible to ${lenderName}.',
                    },
                    {
                        attributes: [{ name: 'anonymizedOnly', value: 'true' }],
                        content: 'Your customers and vendors will be anonymized.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Read-only connection',
                    },
                    {
                        attributes: [],
                        content: 'No information will be altered in your accounting system.',
                    },
                    {
                        attributes: [],
                        content: 'This is a one-way data stream.',
                    },
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Secure',
                    },
                    {
                        attributes: [],
                        content: 'Encryption helps protect your business\' financial data.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Private',
                    },
                    {
                        attributes: [{ name: 'replaceLenderName', value: 'true' }],
                        // lenderName is replaced at runtime so this is correct
                        // eslint-disable-next-line no-template-curly-in-string
                        content: 'Your credentials will never be made accessible to ${lenderName}.',
                    },
                    {
                        attributes: [{ name: 'anonymizedOnly', value: 'true' }],
                        content: 'Your customers and vendors will be anonymized.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Read-only connection',
                    },
                    {
                        attributes: [],
                        content: 'No information will be altered in your accounting system.',
                    },
                    {
                        attributes: [],
                        content: 'This is a one-way data stream.',
                    },
                ]
            }
        },
        {
            tenantName: KnownTenants.chase,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Secure',
                    },
                    {
                        attributes: [],
                        content: 'Encryption helps protect your business\' financial data.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Private',
                    },
                    {
                        attributes: [],
                        content: 'Your accounting software credentials will never be made accessible to Chase for Business®',
                    },
                    {
                        attributes: [{ name: 'anonymizedOnly', value: 'true' }],
                        content: 'Your customers and vendors will be anonymized.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Read-only connection',
                    },
                    {
                        attributes: [],
                        content: 'No information will be altered in your accounting system.',
                    },
                    {
                        attributes: [],
                        content: 'This is a one-way data stream.',
                    },
                ]
            }
        },
        {
            tenantName: KnownTenants.chasesandbox,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Secure',
                    },
                    {
                        attributes: [],
                        content: 'Encryption helps protect your business\' financial data.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Private',
                    },
                    {
                        attributes: [],
                        content: 'Your accounting software credentials will never be made accessible to Chase for Business®',
                    },
                    {
                        attributes: [{ name: 'anonymizedOnly', value: 'true' }],
                        content: 'Your customers and vendors will be anonymized.',
                    },
                    {
                        attributes: [{ name: 'bullet', value: 'true' }],
                        content: 'Read-only connection',
                    },
                    {
                        attributes: [],
                        content: 'No information will be altered in your accounting system.',
                    },
                    {
                        attributes: [],
                        content: 'This is a one-way data stream.',
                    },
                ]
            }
        },
    ]
}

const tenantBorrowerShareableLinkExpiredParagraphs: TenantResourceList = {
    resourceName: ResourceName.tenantShareableLinkExpiredParagraphs,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    'The secure URL you are using has expired and can no longer be used.',
                    // lenderName is replaced at runtime so this is correct
                    // eslint-disable-next-line no-template-curly-in-string
                    'Please contact ${lenderName} to receive a new secure URL.'
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    'The secure URL you are using has expired and can no longer be used.',
                    // lenderName is replaced at runtime so this is correct
                    // eslint-disable-next-line no-template-curly-in-string
                    'Please contact ${lenderName} to receive a new secure URL.'
                ]
            }
        },
        {
            tenantName: KnownTenants.chase,
            resource: {
                paragraphs: [
                    'The secure URL you are using has expired and can no longer be used.'
                ]
            }
        },
        {
            tenantName: KnownTenants.chasesandbox,
            resource: {
                paragraphs: [
                    'The secure URL you are using has expired and can no longer be used.'
                ]
            }
        },
    ]
}

const tenantBorrowerShareableLinkDeletedParagraphs: TenantResourceList = {
    resourceName: ResourceName.tenantShareableLinkDeletedParagraphs,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    'The secure URL you are using has been deleted and can no longer be used.',
                    // lenderName is replaced at runtime so this is correct
                    // eslint-disable-next-line no-template-curly-in-string
                    'If you still need to import financial data, please contact ${lenderName} to receive a new secure URL.'
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    'The secure URL you are using has been deleted and can no longer be used.',
                    // lenderName is replaced at runtime so this is correct
                    // eslint-disable-next-line no-template-curly-in-string
                    'If you still need to import financial data, please contact ${lenderName} to receive a new secure URL.'
                ]
            }
        },
        {
            tenantName: KnownTenants.chase,
            resource: {
                paragraphs: [
                    'The secure URL you are using has been deleted and can no longer be used.'
                ]
            }
        },
        {
            tenantName: KnownTenants.chasesandbox,
            resource: {
                paragraphs: [
                    'The secure URL you are using has been deleted and can no longer be used.'
                ]
            }
        },
    ]
}

const tenantBorrowerIntroBannerText: TenantResourceList = {
    resourceName: ResourceName.tenantBorrowerIntroBannerText,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: ['You are now entering the Strongbox data collection site.']
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: ['You are now entering the Strongbox data collection site.']
            }
        },
    ]
}

const tenantReportList: TenantResourceList = {
    resourceName: ResourceName.tenantReportList,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Financial Statements'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsReceivable }],
                        content: 'Accounts Receivable Transactions'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsPayable }],
                        content: 'Accounts Payable Transactions'
                    },
                    {
                        attributes: [],
                        content: 'General Ledger Entries'
                    },
                    {
                        attributes: [],
                        content: 'Chart of Accounts'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Financial Statements'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsReceivable }],
                        content: 'Accounts Receivable Transactions'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsPayable }],
                        content: 'Accounts Payable Transactions'
                    },
                    {
                        attributes: [],
                        content: 'General Ledger Entries'
                    },
                    {
                        attributes: [],
                        content: 'Chart of Accounts'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.wipfli,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Financial Statements'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsReceivable }],
                        content: 'Accounts Receivable Transactions'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsPayable }],
                        content: 'Accounts Payable Transactions'
                    },
                    {
                        attributes: [],
                        content: 'General Ledger Entries'
                    },
                    {
                        attributes: [],
                        content: 'Chart of Accounts'
                    },
                    {
                        attributes: [],
                        content: 'Contact List - City, State and Postal Code'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.mlrpc,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Financial Statements'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsReceivable }],
                        content: 'Accounts Receivable Transactions'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsPayable }],
                        content: 'Accounts Payable Transactions'
                    },
                    {
                        attributes: [],
                        content: 'General Ledger Entries'
                    },
                    {
                        attributes: [],
                        content: 'Chart of Accounts'
                    },
                    {
                        attributes: [],
                        content: 'Contact List - City, State and Postal Code'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.cohencpa,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Financial Statements'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsReceivable }],
                        content: 'Accounts Receivable Transactions'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsPayable }],
                        content: 'Accounts Payable Transactions'
                    },
                    {
                        attributes: [],
                        content: 'General Ledger Entries'
                    },
                    {
                        attributes: [],
                        content: 'Chart of Accounts'
                    },
                    {
                        attributes: [],
                        content: 'Contact List - City, State and Postal Code'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.bmss,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Financial Statements'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsReceivable }],
                        content: 'Accounts Receivable Transactions'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.accountsPayable }],
                        content: 'Accounts Payable Transactions'
                    },
                    {
                        attributes: [],
                        content: 'General Ledger Entries'
                    },
                    {
                        attributes: [],
                        content: 'Chart of Accounts'
                    },
                    {
                        attributes: [],
                        content: 'Contact List - City, State and Postal Code'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.chase,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Balance Sheet'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Income Statement'
                    },
                ]
            }
        },
        {
            tenantName: KnownTenants.chasesandbox,
            resource: {
                paragraphs: [
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Balance Sheet'
                    },
                    {
                        attributes: [{ name: 'requires-group', value: knownAccountingGroupIds.financialStatements }],
                        content: 'Income Statement'
                    },
                ]
            }
        }
    ]
}

const basicEnterBusinessinfoPrompt = 'We need to know a little about you first. Please enter the name of your business and an email address.';

const tenantEnterBusinessInfoPrompts: TenantResourceList = {
    resourceName: ResourceName.connectEnterBusinessInfo,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    basicEnterBusinessinfoPrompt
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    basicEnterBusinessinfoPrompt
                ]
            }
        }
    ]
}

// cpademo, bennetttthrasher, mlrpc, wiss

const tenantSelectPkgPrompts: TenantResourceList = {
    resourceName: ResourceName.connectSelectAccountingPkg,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: 'Please select your accounting system to get started',
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: 'Select an accounting system to connect the company file to Strongbox.'
        },
    ]
}

// capdemo, benettthtrahser, mlrpc, wiss

const tenantDocPrompts: TenantResourceList = {
    resourceName: ResourceName.connectTenantDocsUploadPrompt,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: 'To upload additional documents into Strongbox,',
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: 'To upload additional documents into Strongbox,',
        },
    ],
}

const tenantLinkPrompts: TenantResourceList = {
    resourceName: ResourceName.connectTenantUploadLink,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: 'click here',
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: 'click here',
        }
    ],
}

const tenantEmailConfirmed: TenantResourceList = {
    resourceName: ResourceName.tenantConfirmationText,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    'Thank you!',
                    'Your submission has been confirmed.'
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    'Thank you!',
                    'Your submission has been confirmed.'
                ]
            }
        }
    ]
}

const tenantEmailConfirming: TenantResourceList = {
    resourceName: ResourceName.tenantConfirmingText,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    'Please wait just a moment...',
                    'We are confirming your submission.'
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    'Please wait just a moment...',
                    'We are confirming your submission.'
                ]
            }
        }
    ]
}

const loginExpiredP1 = 'Your login has expired, you will need to re-enter your credentials to continue.';
const loginExpiredP2 = 'Press the button below to return to the login window.';

const tenantAuthorizationTimedOut: TenantResourceList = {
    resourceName: ResourceName.tenantAuthorizationTimedOut,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    loginExpiredP1,
                    loginExpiredP2
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    loginExpiredP1,
                    loginExpiredP2
                ]
            }
        }
    ]
}

const slidingWindowExpirationP1 = 'Your login session has expired due to inactivity.';

const tenantSlidingWindowAuthorizationTimedOut: TenantResourceList = {
    resourceName: ResourceName.tenantSlidingWindowTimedOut,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    slidingWindowExpirationP1
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    slidingWindowExpirationP1
                ]
            }
        }
    ]
}

// bennettthrasher, cpademo, mlrpc, wiss 
    /*
    < img
alt = { 'Import step'}
src = { lineItem.img }
className = {`clear-image-fade-in ${visibleClass}`}
/>
*/

const tenantProgressSteps: TenantResourceList = {
    resourceName: ResourceName.tenantProgressSteps,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                lineItems: [
                    {
                        img: (className?: string) => <LockIcon className={className} />,
                        text: 'Establishing secure connection'
                    },
                    {
                        img: (className?: string) => <CheckIcon className={className} />,
                        text: 'Connection successful'
                    },
                    {
                        img: (className?: string) => <CheckIcon className={className} />,
                        text: 'Gathering financial data'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                lineItems: [
                    {
                        img: (className?: string) => <LockIcon className={className} />,
                        text: 'Establishing secure connection'
                    },
                    {
                        img: (className?: string) => <CheckIcon className={className} />,
                        text: 'Connection successful'
                    },
                    {
                        img: (className?: string) => <CheckIcon className={className} />,
                        text: 'Gathering financial data'
                    }
                ]
            }
        }
    ]
}

const downloadReady = 'Click the button below to download a copy of the submitted financial data.'

const tenantDownloadUserCopy: TenantResourceList = {
    resourceName: ResourceName.tenantDownloadUserCopy,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    downloadReady
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    downloadReady
                ]
            }
        }
    ]
}

const downloadNotExisting = 'Sorry, the file you attempted to download doesn\'t exist.'

const tenantDownloadNotExisting: TenantResourceList = {
    resourceName: ResourceName.tenantDownloadNotExisting,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    downloadNotExisting
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    downloadNotExisting
                ]
            }
        }
    ]
}

const linkComplete = 'Success! You may now close this window or choose from the following:'
const linkCompleteImportOnly = 'Success! You may now close this window or import additional financials:'
const linkCompleteNoActions = 'Success! You may now close this window.'

const tenantLinkComplete: TenantResourceList = {
    resourceName: ResourceName.tenantLinkComplete,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    linkComplete
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    linkComplete
                ]
            }
        },
    ]
}

const tenantLinkCompleteImportOnly: TenantResourceList = {
    resourceName: ResourceName.tenantLinkCompleteImportOnly,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    linkCompleteImportOnly
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    linkCompleteImportOnly
                ]
            }
        }
    ]
}

const tenantLinkCompleteNoActions: TenantResourceList = {
    resourceName: ResourceName.tenantLinkCompleteNoActions,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    linkCompleteNoActions
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    linkCompleteNoActions
                ]
            }
        },
    ]
}

const tenantLinkReadyToUploadMoreDocs: TenantResourceList = {
    resourceName: ResourceName.tenantLinkReadyToUploadMoreDocs,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    {
                        attributes: [],
                        content:'Now you\'re ready to upload documents.'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    {
                        attributes: [],
                        content: 'Now you\'re ready to upload documents.'
                    }
                ]
            }
        }
    ]
}

const tenantLinkSubmitMoreDocs: TenantResourceList = {
    resourceName: ResourceName.tenantLinkSubmitMoreDocs,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    {
                        attributes: [],
                        content: 'Need to submit more documents to our team?'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    {
                        attributes: [],
                        content: 'Need to submit more documents to our team?'
                    }
                ]
            }
        }
    ]
}

const tenantCollectFinancialsWarning: TenantResourceList = {
    resourceName: ResourceName.tenantCollectFinancialsWarning,
    resources: [
        {
            tenantName: KnownTenants.rsm,
            resource: {
                paragraphs: [
                    '<b>Note from RSM Corporate:</b> Data connected to Strongbox should be from companies not subject to GDPR.',
                    'Companies subject to GDPR include those located in the following countries: Austria, Belgium, Bulgaria, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, the Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, and the United Kingdom.'
                ]
            }
        }
    ]
}

const tenantDataCollectionGroupDescriptions: TenantResourceList = {
    resourceName: ResourceName.tenantDataCollectionGroupDescriptions,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements }
                        ],
                        content: 'Balance Sheets and Income Statements by year, month, and interim period. Includes view by Class where applicable.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Available for all accounting systems',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions }
                        ],
                        content: 'All transactions for the given time period. Note this can potentially be ' +
                            'a large data collection and may slow down the generation of workbook output.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable }
                        ],
                        content: 'Receivables data and A/R analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta or Sage Intacct',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable }
                        ],
                        content: 'Payable data and A/P analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta or Sage Intacct',
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements }
                        ],
                        content: 'Balance Sheets and Income Statements by year, month, and interim period. Includes view by Class where applicable.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Available for all accounting systems',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions }
                        ],
                        content: 'All transactions for the given time period. Note this can potentially be ' +
                            'a large data collection and may slow down the generation of workbook output.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable }
                        ],
                        content: 'Receivables data and A/R analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta or Sage Intacct',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable }
                        ],
                        content: 'Payable data and A/P analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta or Sage Intacct',
                    }
                ]
            }
        },
    ]
}

const tenantDataCollectionGroupDescriptionsExcel: TenantResourceList = {
    resourceName: ResourceName.tenantDataCollectionGroupDescriptionsExcel,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements }
                        ],
                        content: 'Balance Sheets and Income Statements by year, month, and interim period. Includes view by Class where applicable.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Available for all accounting systems and Excel upload',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions }
                        ],
                        content: 'All transactions for the given time period. Note this can potentially be ' +
                            'a large data collection and may slow down the generation of workbook output.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta or Excel upload',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable }
                        ],
                        content: 'Receivables data and A/R analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta, Sage Intacct or Excel upload',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable }
                        ],
                        content: 'Payable data and A/P analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta, Sage Intacct or Excel upload',
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements }
                        ],
                        content: 'Balance Sheets and Income Statements by year, month, and interim period. Includes view by Class where applicable.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.financialStatements },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Available for all accounting systems and Excel upload',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions }
                        ],
                        content: 'All transactions for the given time period. Note this can potentially be ' +
                            'a large data collection and may slow down the generation of workbook output.',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.transactions },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta or Excel upload',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable }
                        ],
                        content: 'Receivables data and A/R analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsReceivable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta, Sage Intacct or Excel upload',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable }
                        ],
                        content: 'Payable data and A/P analyses',
                    },
                    {
                        attributes: [
                            { name: 'id', value: knownAccountingGroupIds.accountsPayable },
                            { name: 'bold', value: '' },
                            { name: 'italic', value: '' }
                        ],
                        content: '* Not currently available for MYOB Business Beta, Sage Intacct or Excel upload',
                    }
                ]
            }
        },
    ]
}

const tenantLenderAdditionalMessagingSelectAccountingDataPage: TenantResourceList = {
    resourceName: ResourceName.tenantAdditionalMessagingSelectAccountingDataPage,
    resources: [
        {
            tenantName: KnownTenants.defaultLender,
            resource: {
                paragraphs: [
                    {
                        attributes: [
                            { name: 'bold', value: '' }
                        ],
                        content: 'AR and AP are currently collected automatically to power Strongbox analyses.'
                    }
                ]
            }
        },
        {
            tenantName: KnownTenants.defaultAccountant,
            resource: {
                paragraphs: [
                    {
                        attributes: [
                            { name: 'bold', value: '' }
                        ],
                        content: 'AR and AP are currently collected automatically to power Strongbox analyses.'
                    }
                ]
            }
        }
    ]
}


/*
 * Modify this list (tenantResourceLists) as necessary if you add any tenantResourceList
 * structures above.  They will be automatically available once they're added to this list.
 */

export const tenantResourceLists = [
    tenantWelcomeText,
    tenantEnterBusinessInfoPrompts,
    tenantSelectPkgPrompts,
    tenantDocPrompts,
    tenantLinkPrompts,
    tenantEmailConfirmed,
    tenantEmailConfirming,
    tenantProgressSteps,
    tenantAuthorizationTimedOut,
    tenantSlidingWindowAuthorizationTimedOut,
    tenantDownloadUserCopy,
    tenantDownloadNotExisting,
    tenantLinkComplete,
    tenantLinkCompleteImportOnly,
    tenantLinkCompleteNoActions,
    tenantLinkSubmitMoreDocs,
    tenantLinkReadyToUploadMoreDocs,
    tenantCollectFinancialsWarning,
    tenantReportList,
    tenantSelectAccountingSystemSupplemental,
    tenantBorrowerParametersBullets,
    tenantBorrowerIntroBannerText,
    tenantBorrowerShareableLinkExpiredParagraphs,
    tenantBorrowerShareableLinkDeletedParagraphs,
    tenantDataCollectionGroupDescriptions,
    tenantDataCollectionGroupDescriptionsExcel,
    tenantLenderAdditionalMessagingSelectAccountingDataPage,
];

/*
 * This is purely exemplary for the time being. Color values and font values should be constants
 * and easier to change.   These values are identical to the default values in Main.scss.
 *
 * const bdolinkPalette: TenantPalette = {
 *   paletteColletion: {
 *       [PaletteItemNames.h1Login]: {
 *           name: 'h1',
 *           page: 'login',
 *           value: {
 *               color: '#1b1443',
 *               fontFamily: 'sofia-pro, sans-serif',
 *           }
 *       }
 *   }
 * }
 *
 * export const tenantPalettes: TenantResourceList = {
 *   resourceName: ResourceName.stylePalette,
 *   resources: [
 *       {
 *           tenantName: KnownTenants.bdo,
 *           resource: bdokPalette,
 *       }
 *   ],
 * }
 *  
 */

export async function GetTenantBasicSettings(tenantName: string): Promise<TenantBasicSettings> {
    if (!tenantName) {
        LogMessage(
            `tenantName is undefined in GetTenantBasicSettings, defaulting to strongbox`,
            SeverityLevel.Warning
        );
        console.error('tenantName is undefined in GetTenantBasicSettings, defaulting to strongbox');
        tenantName = KnownTenants.strongbox;
    }

    const url = `${assetBlobContainer}/brandassets/${tenantName}/config.json`;

    try {
        const response = await fetchWrapper(url, {}, true, false);
        if (!response.ok) {
            return defaultBasicTenantSettings;
        }

        return await response.json();
    } catch (exception) {
        return defaultBasicTenantSettings;
    }
}

export async function GetTenantCSSConfig(tenantName: string): Promise<BrandConfig> {
    if (!tenantName) {
        LogMessage(
            `tenantName is undefined in GetTenantCSSConfig, continuing with default settings`,
            SeverityLevel.Warning
        );
        console.error('tenantName is undefined in GetTenantCSSConfig, continuing with default settings');
        tenantName = KnownTenants.strongbox;
    }

    const url = `${assetBlobContainer}/brandassets/${tenantName}/brandconfig.json`;

    try {
        const response = await fetchWrapper(url, {}, true, false);

        if (!response.ok) {
            LogMessage(
                `failed to download brand settings for ${tenantName}. Using default.`,
                SeverityLevel.Warning,
                {
                    tenantName,
                }
            );
            return defaultBrandConfig;
        }

        const config = await response.json() as BrandConfig;

        if (!!config.cssSettings) {
            const r = document.querySelector(':root') as HTMLElement;

            Object.keys(config.cssSettings).forEach(key => {
                try {
                    r.style.setProperty(key, config.cssSettings[key]);
                } catch (setPropException) {
                    // Just continue, default values get used if we don't change anything.
                }
            });
        }

        return config;

    } catch (exception) {
        LogException(
            `Exception thrown downloading brand config for ${tenantName}`,
            exception,
            {
                tenantName,
            }
        )
        // Probably not an error.  It's okay if there are no branding settings, just means they weren't overridden.
        return defaultBrandConfig;
    }
}

export function GetTenantNameFromHostName(hostname: string): KnownTenants {
    const tenantOverride = localStorage.getItem(tenantOverrideKey);
    if (tenantOverride !== null) {
        const override = Object.keys(KnownTenants).find(key => KnownTenants[key as keyof typeof KnownTenants] === tenantOverride);
        if (!!override) {
            return KnownTenants[override as keyof typeof KnownTenants];
        }
    }

    const hostCopy = hostname.toLowerCase();

    // .split will always produce a result with at least 1 element
    const tenantNameParts = hostCopy.split('.');
    if (tenantNameParts.length < 3) {
        return KnownTenants.strongbox;
    }
    const testDomain = `.${tenantNameParts[1]}.${tenantNameParts[2]}`;
    if (!validDomains.find(domain => domain === testDomain)) {
        return KnownTenants.strongbox;
    }

    return subdomainMap[tenantNameParts[0]] || KnownTenants.strongbox;
}
